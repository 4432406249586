import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0023 = () => (
  <Wrapper>
    <ColumnH1 label="アルバイトやパートにも有給休暇がある！付与日数や条件は？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        従業員を雇用する際に、避けて通ることが出来ない法律に労働基準法があります。
        <br />
        そして、労働者としても注目が集まる部分として有給休暇が挙げられます。有給休暇はアルバイトやパートは除外するということにはなりません。
        <br />
        しかし、一般的には正社員よりも働く時間が少ないというケースが多く、その場合の有給休暇の付与方式について解説してまいります。
      </p>
      <img src="/images/column/details/c0023.jpg" alt="キンクラコラム画像23" />
      <h1>アルバイト・パートの有給休暇</h1>
      <p>
        有給休暇が付与される原則的な考え方は、採用されてから6か月以上継続勤務し、全労働日の8割以上出勤した場合に継続または分割した10日の有給休暇が付与されます。
        <br />
        ここで問題があります。週5日・40時間勤務する正社員とそれに満たない時間しか勤務しないアルバイトやパートの付与日数が同じ場合、中立的とは言えないとの考え方も一理あります。そこで、
        <span>「比例付与」</span>
        と言い、正社員よりも労働時間が短い従業員向けに通常の付与日数よりも減算した日数を付与する方式が法律上定められています。
      </p>
      <h1>比例付与とは</h1>
      <p>
        <span>
          週の所定労働時間が30時間未満であり、かつ、週の所定労働日数が4日以下の労働者または年間の所定労働日数が216日以下の労働者
        </span>
        が対象となります。比例付与の対象となる場合、通常よりも少ない日数で付与しても違法とはなりません（個別の労働契約で比例付与方式ではなく、通常の付与とすることで合意していた場合は除く）。
      </p>
      <div className="link-div">
        出典
        <p className="link-p">
          <Link
            className="p-list-link"
            to="https://www.mhlw.go.jp/new-info/kobetu/roudou/gyousei/dl/140811-3.pdf"
            target="_blank"
            rel="noreferrer"
          >
            厚生労働省
          </Link>
        </p>
      </div>
      <p>
        比例付与を運用するにあたっての注意点は、例えば一日2時間しか勤務しないアルバイトであっても週の所定労働日数が5日で契約している場合には「週の所定労働日数が4日以下」にはあたらないことから、比例付与の対象とすることはできないということです。
      </p>
      <h1>比例付与の計算式</h1>
      <p>
        <span>
          通常の労働者の有給休暇の日数×比例付与対象者の所定労働日数/通常の労働者の所定労働日数（5.2日）
        </span>
        となります。
        <br />
        計算の結果、1日未満の端数が生じた場合は切り捨てることとされています。例えば採用から2年6か月経過した所定労働日数が3日のアルバイトの場合を確認しましょう。
        <br />
        <br />
        12日×3/5.2=6.92となり、「6日」の有給休暇が付与されるという理屈です。
        <br />
        <br />
        なお、週の所定労働時間にばらつきがある場合は、付与日直前の実績を元に年間の所定労働日数を算出することとして差し支えありません。例として、直近6か月の実働が60日であった場合、60日×2＝120日を所定労働日数とみなすということです。
      </p>
      <h1>比例付与対象者の所定労働日数が変更となった場合の取り扱い</h1>
      <p>
        比例付与対象者が年度の途中で所定労働日数に変更が生じた場合、そもそも有給休暇は「基準日」において発生するため、当初の日数のままとなります。もちろん労使合意の元、有給休暇を「増やす」こと自体は自由に行うことができます。
      </p>
      <Introduction001 />
      <h1>複数事業場で勤務するアルバイトに対する取り扱い</h1>
      <p>
        正社員は副業禁止とするもののアルバイトやパートに限り副業を認め、そこで、かけもちで勤務するというケースは珍しくありません。そのような場合の有給休暇について確認しましょう。
        <br />
        労働時間については異なる会社に勤める場合であっても労働時間は通算されます。しかし、
        <span>有給休暇はあくまで会社ごとに付与</span>
        するため、甲事業所と乙事業所の時間を通算して通常の付与日数で付与されるという理屈は成り立ちません。いわゆる労働時間の通算規定はあくまで「労働時間」に限定して通算するということであり、有給休暇にまで波及しないことはおさえておく必要があります。
      </p>
      <h1>週以外の期間で所定労働日数が定められている場合</h1>
      <p>
        比例付与の対象者であるか否かを精査する際には週の所定労働日数が4日以下であることの他に1年間の所定労働に数が216日以下の者という要件があります。これは「週以外の期間によって所定労働日数が定められている労働者」であることが前提となり、例えば月の前半の繁忙期のみ労働するというケースや季節的に所定労働日数が異なり、年を単位として所定労働日数が定められている労働者が該当します。よって、上記に該当しないケースについては原則として週所定労働日数を基準として考えます。
        <br />
        結論としては週以外の期間によって所定労働日数が定められている労働者については
        <span>
          216日以下であることを精査し、比例付与対象者であるか否かを判別する
        </span>
        必要があります。
      </p>
      <h1>出勤率が8割未満の場合</h1>
      <p>
        アルバイトやパートで契約している労働者の心理的な背景として、子供の通院などで突発的に休みが必要となるために正社員を選ばなかったということもあります。そこで、出勤率が8割に満たなかった場合は有給休暇を与える義務自体はありませんが、恩恵的に付与するということも労使間で取り決めることは可能です。
      </p>
      <h1 className="read">最後に</h1>
      <p>
        アルバイトやパートであっても有給休暇が付与されるという部分については周知が進んできている印象ですが、付与日数が明確化されていない場合や事業主の裁量で付与しているケースも散見されます。
        <br />
        明確化することで対応に差が生じる余地がなく、中立的な労務管理が可能となり、従業員との信頼関係においてもプラスの効果が波及するものと考えます。
        <br />
        <br />
        特に比例付与の算出式において用いる「5.2日」は平成13年4月1日に改正されている数値です。よって、古い就業規則を継続して運用している場合や、近年の法改正には適宜対応しているものの、過去の法改正には対応しているか不明瞭な場合は一度見直しをしておくことが肝要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0023
